@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300@400;500;700&display=swap");

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #212121;
  -webkit-border-radius: 1ex;
}
img {
  max-width: 100%;
}
.header-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.live_heading {
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  background: -webkit-linear-gradient(
    top,
    #8f6b29,
    #fde08d,
    #df9f28
  ) !important;
  /* -webkit-background-clip: text !important; */
  -webkit-text-fill-color: #ffc63f70 !important;
  font-family: Arial, Arial, Tahoma, Serif;
  margin: 25px 0px 20px 0px;
}
.Evolution_Games {
  display: flex;
  justify-content: start;
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  scrollbar-color: #132d44 #1c2634;
}
.listings {
  position: relative;
}
.listings .right-arrow {
  position: absolute !important;
  top: 0% !important;
  right: 0 !important;
  left: auto !important;
}
.listings .left-arrows svg {
  position: ABSOLUTE;
  height: 35px;
  width: 35px;
  color: gold;
  top: 110px;
  left: -2px;
}
.listings .left-arrows {
  position: relative;
  display: block;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 1px;
  z-index: 9;
}
.Provider_Games .shadow.rounded.position-relative {
  width: 265px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.Payments_Games .shadow.rounded.position-relative {
  width: 260px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.shadow.rounded.position-relative {
  width: 285px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.Provider_Games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #0a0f19;
  padding: 10px;
  border-radius: 10px;
}
.Provider_Games .shadow.rounded.position-relative img {
  margin: 0px auto;
  width: 80%;
}
.Payments_Games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.text-left {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
}
._container_1ib6x_1 {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 90px !important;
  width: 100%;
}
#scroller {
  margin-top: 90px;
}
.slick-next {
  right: 15px !important;
}
.slick-prev {
  left: 15px !important;
  z-index: 9;
}
.support_icons {
  width: 26px;
  height: 26px;
}
.social_icons {
  width: 32px;
  border-radius: 50px;
}
.text-center.mb-4 {
  font-weight: 100;
  color: goldenrod;
  background: #0e1422;
  border-radius: 5px;
  padding: 10px 0px;
}
.Toastify {
  position: relative !important;
  z-index: 9999999999 !important;
}
img.provider-img {
  width: 78px;
}
.privacy_policy {
  padding-bottom: 71px;
}
.withdraw_btn a.btn_primary.btn-primary {
  padding: 9px 30px;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 120px;
  margin-left: 9px;
}
.email_contact {
  font-size: large;
}

@media only screen and (max-width: 767px) {
  .MobileGamesSection.slots-sectionPage {
    margin: 25px 8px !important;
  }
  .MobileGamesSection.slots-sectionPage ul a.casino_games {
    width: 48.2% !important;
  }
  .MobileGamesSection.slots-sectionPage .SlotsTabsSection ul li {
    margin: 0 4px !important;
  }
  *:not(:empty) {
    transform-style: inherit !important;
  }
  .WithdrawPageSection .WithDrawSection.tabs-area {
    width: 100% !important;
  }
  .WithdrawGif {
    display: none;
  }
  .WithDrawSection.tabs-area ul li button {
    font-size: 11px !important;
  }
  .WithDrawBankDetails form {
    padding: 30px 0 0 !important;
  }
  .steps-canvas .form-steps .btn {
    margin: 0 auto;
    margin-top: 0 !important;
  }
  .header .headerRight ul li .balance-amount span {
    padding: 0 5px !important;
    font-size: 9px !important;
  }

  .form-steps img {
    width: 200px;
    text-align: center;
    margin: 0 auto 12px;
  }
  .steps-canvas .form-steps .sportsSection {
    width: 170px !important;
  }
  .populaGamesSection ul {
    justify-content: flex-start !important;
  }
  .header .headerRight ul li .deposit-header {
    width: 22px !important;
    height: 22px !important;
  }
  .sports_page .nav .nav-item,
  .custom-tabs .nav .nav-item {
    margin: 0 3px !important;
  }
  .sports_page .nav a.openbets,
  .custom-tabs .nav a.openbets {
    font-size: 12px;
    padding: 6px !important;
  }
  .sport-buttons ul li a .sports-content-text {
    flex-direction: row !important;
    height: 100%;
    align-items: center;
  }
  .sport-buttons ul li a .sports-content-text span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .sport-buttons ul li a .sports-content-text img {
    margin-right: 3px;
  }
  .header .headerRight ul li .balance-amount img {
    width: 22px !important;
    height: 22px !important;
  }
  .header .searchbar {
    width: 22px !important;
    height: 22px !important;
  }
  .header .searchbar svg {
    font-size: 15px !important;
  }
  .header .menu-btn {
    margin-right: 8px;
    padding: 0 !important;
  }
  .header .headerRight ul li .balance-amount {
    font-size: 13px !important;
  }
  .afterlogin .headerRight .searchbar {
    margin: 0 !important;
  }
  .sports_page .nav,
  .custom-tabs .nav {
    justify-content: start !important;
  }
  .footer .sectionDivide .singleSection {
    width: 100% !important;
  }
  .SocialMediaSection ul li {
    width: 48% !important;
  }
  .SocialMediaSection ul li a {
    font-size: 10.5px !important;
    padding: 10px 3px !important;
  }
  .SocialMediaSection ul li a img {
    margin-right: 7px !important;
  }
  .footer .footer-links ul li:after {
    right: -5px !important;
  }
  .footer .footer-links ul li:last-child::after {
    display: none;
  }
  .footer .providers_logo h4,
  .footer .paymentspartner h5 {
    font-size: 16px !important;
    text-align: left !important;
  }
  .footer .paymentspartner ul li img {
    width: 30px !important;
    margin: 0 !important;
  }
  .footer .paymentspartner ul {
    gap: 10px;
  }
  .footer p {
    font-weight: inherit !important;
  }
  .live_tv_box,
  .iframe-div {
    height: 260px !important;
  }
  .live_tv_box .iframe-div iframe {
    height: 260px !important;
  }
  .amount_data {
    flex-wrap: wrap;
    text-align: center;
  }
  .cancel_bonus {
    background: red;
    padding: 4px 2px;
    font-size: 10px;
    display: block;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
    text-transform: uppercase;
    justify-content: center !important;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .gatewaySection .tab-content .tab-pane.active .cards1 {
    width: 48% !important;
  }
  .gatewaySection .tab-content {
    padding: 20px !important;
  }
  .gatewaySection {
    max-width: 100% !important;
  }

  .filter-added .dropdown {
    width: 65% !important;
  }
  .live-deal-section ul li {
    width: 49% !important;
    margin: 0.5% !important;
  }
  .live-deal-section ul {
    flex-wrap: wrap;
  }
  .betslip-content.inline_betslip .auto-stack ul {
    flex-wrap: wrap;
  }
  .betslip-content.inline_betslip .auto-stack ul li {
    margin: 1% !important;
    width: 23% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info,
  .sports_page .tab-pane .game-listing .match-info {
    display: block !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .match-name,
  .sports_page .tab-pane .game-listing .match-info .match-name {
    width: 100% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing,
  .sports_page .tab-pane .game-listing .match-info .listing {
    padding-bottom: 5px !important;
  }
  .betslip_popup .modal-body .stack-win-value .odds div {
    width: 100% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing li,
  .sports_page .tab-pane .game-listing .match-info .listing li {
    margin-right: 3px !important;
    width: 100% !important;
  }
  .page-details .game-top-area .date,
  .page-details .game-top-area .range {
    display: none !important;
  }
  .page-details .game-points ul li {
    border-bottom: 1px solid #2f3940 !important;
    display: flex !important;
    padding-left: 0.5rem !important;
  }
  .page-details .game-points ul li .team-name {
    color: var(--headClr) !important;
    flex-direction: revert !important;
    justify-content: space-between !important;
    overflow: hidden !important;
    padding: 0 0.25rem 0 0 !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: calc(100% - 150px) !important;
  }
  .page-details .game-points ul li .status-btns {
    align-items: center !important;
    display: flex !important;
    width: 140px !important;
  }
  .page-details .game-points ul li .status-btns .info-block:first-child {
    display: none !important;
    margin-left: 0 !important;
  }
  .page-details .game-points ul li .status-btns .info-block {
    display: none !important;
    width: inherit !important;
  }
  .page-details .game-points ul li .status-btns .info-block:nth-child(3),
  .page-details .game-points ul li .status-btns .info-block:nth-child(4) {
    display: flex !important;
    width: 50% !important;
  }
  .page-details .game-points ul li .status-btns .info-block.no:nth-child(2) {
    display: flex !important;
    width: 50% !important;
  }
  .banner_sec .banner_imgboxes .img_box {
    flex: 0 0 100% !important;
  }
  .page-details .title span {
    background-color: inherit !important;
  }

  .header .headerRight ul li.head-social {
    display: none !important;
  }
  .header .headerRight ul li.balance {
    display: block !important;
  }
  .support-header .chat-call-support {
    flex-wrap: wrap;
  }
  .support-header .chat-call-support .chat-box {
    width: 90% !important;
    padding: 25px 0 !important;
  }
  .support-header .chat-call-support .chat-box ul {
    justify-content: start !important;
  }
  .support-header .chat-call-support .chat-box p {
    margin-bottom: 0 !important;
    font-size: 20px !important;
  }
  .support-header .chat-call-support .chat-box h2 {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .support-header .chat-call-support .chat-box .email-section p {
    font-size: 13px !important;
  }
  .support-header .chat-call-support .chat-box .email-section a {
    font-size: 18px !important;
    font-weight: 700;
  }
  .support-header .chat-call-support .chat-box:nth-child(2) {
    border-bottom: 1px solid #a5a5a5;
    border-top: 1px solid #a5a5a5;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .password-note {
    margin-bottom: 12px;
  }
  .password-note ul li {
    font-size: 12px;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .ezugi_iframe iframe {
    max-height: calc(100vh - 65px) !important;
    height: calc(100vh - 65px) !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .ezugi_iframe iframe {
    height: calc(100vh - 200px) !important;
    max-height: calc(100vh - 200px) !important;
  }
}
